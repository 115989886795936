'use client';
import React, { createContext, useContext, useState, useCallback, ReactNode } from 'react';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import { useUserService } from '@dr-pam/common-components/Services/UserService';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';

type ConsumedContentProviderContextType = {
	articleIds: string[];
	markArticleAsRead: (articleId: string) => Promise<void>;
	unmarkArticleAsRead: (articleId: string) => Promise<void>;
	isArticleRead: (articleId: string) => boolean;
	resourceIds: string[];
	markResourceAsDownloaded: (resourceId: string) => Promise<void>;
	unmarkResourceAsDownloaded: (resourceId: string) => Promise<void>;
	isResourceDownloaded: (resourceId: string) => boolean;
	hasLoader: (id: string) => boolean;
	isLoading: boolean;
};

const ConsumedContentProviderContext = createContext<ConsumedContentProviderContextType | null>(null);

type ConsumedContentProviderProviderProps = {
	initialArticleIds?: string[];
	initialResourceIds?: string[];
	children?: ReactNode;
};

export function ConsumedContentProviderProvider(props: ConsumedContentProviderProviderProps) {
	const { children, initialArticleIds, initialResourceIds } = props;
	const [articleIds, setArticleIds] = useState<string[]>(initialArticleIds ?? []);
	const [resourceIds, setResourceIds] = useState<string[]>(initialResourceIds ?? []);

	const { addLoader, removeLoader, isLoading, hasLoader } = useLoadTracker();
	const userService = useUserService();

	const markArticleAsRead = useCallback(
		async (articleId: string) => {
			if (articleIds.includes(articleId)) {
				return;
			}
			const loader = addLoader(articleId);
			try {
				const request = userService.markArticleAsRead(articleId);
				await request.response;
				setArticleIds((current) => [...current, articleId]);
			} catch (error) {
				NotificationUtils.showError(error as Error, 'Error marking article as read');
			} finally {
				removeLoader(loader);
			}
		},
		[addLoader, articleIds, removeLoader, userService],
	);

	const unmarkArticleAsRead = useCallback(
		async (articleId: string) => {
			if (!articleIds.includes(articleId)) {
				return;
			}
			const loader = addLoader(articleId);
			try {
				const request = userService.unmarkArticleAsRead(articleId);
				await request.response;
				setArticleIds((current) => current.filter((id) => id !== articleId));
			} catch (error) {
				NotificationUtils.showError(error as Error, 'Error marking article as unread');
			} finally {
				removeLoader(loader);
			}
		},
		[addLoader, articleIds, removeLoader, userService],
	);

	const isArticleRead = useCallback((articleId: string) => articleIds.includes(articleId), [articleIds]);

	const markResourceAsDownloaded = useCallback(
		async (resourceId: string) => {
			if (resourceIds.includes(resourceId)) {
				return;
			}
			const loader = addLoader(resourceId);
			try {
				const request = userService.markResourceAsDownloaded(resourceId);
				await request.response;
				setResourceIds((current) => [...current, resourceId]);
			} catch (error) {
				NotificationUtils.showError(error as Error, 'Error marking resource as downloaded');
			} finally {
				removeLoader(loader);
			}
		},
		[addLoader, removeLoader, resourceIds, userService],
	);

	const unmarkResourceAsDownloaded = useCallback(
		async (resourceId: string) => {
			if (!resourceIds.includes(resourceId)) {
				return;
			}
			const loader = addLoader(resourceId);
			try {
				const request = userService.unmarkResourceAsDownloaded(resourceId);
				await request.response;
				setResourceIds((current) => current.filter((id) => id !== resourceId));
			} catch (error) {
				NotificationUtils.showError(error as Error, 'Error marking resource as downloaded');
			} finally {
				removeLoader(loader);
			}
		},
		[addLoader, removeLoader, resourceIds, userService],
	);

	const isResourceDownloaded = useCallback((resourceId: string) => resourceIds.includes(resourceId), [resourceIds]);

	const value: ConsumedContentProviderContextType = {
		articleIds,
		markArticleAsRead,
		unmarkArticleAsRead,
		isArticleRead,
		resourceIds,
		markResourceAsDownloaded,
		unmarkResourceAsDownloaded,
		isResourceDownloaded,
		isLoading,
		hasLoader,
	};

	return <ConsumedContentProviderContext.Provider value={value}>{children}</ConsumedContentProviderContext.Provider>;
}

export function useConsumedContentProvider() {
	const context = useContext(ConsumedContentProviderContext);
	if (!context) {
		throw new Error('useConsumedContentProvider must be used within a ConsumedContentProviderProvider');
	}
	return context;
}
