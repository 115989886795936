'use client';

import React, { useEffect, useState } from 'react';
import SessionProvider, { SessionProviderProps } from '@dr-pam/common-components/Components/Page/SessionProvider';
import { FingerprintJsProService } from '@dr-pam/common-components/Services/FingerprintService';
import { SessionData } from 'express-session';
import FetchUtils from '@dr-pam/common-components/Utils/FetchUtils';
import { useHotjarService } from '@dr-pam/common-components/Services/HotjarService';
import HotjarUserData from '../../config/HotjarUserData';
import { useRuntimeConfigProvider } from '@dr-pam/common-components/Config/RuntimeConfigProvider';
import RuntimeConfig from '../../config/RuntimeConfig';

export const dynamic = 'force-dynamic';

export default function PspSessionProvider(props: SessionProviderProps<PrismaJson.ApiSessionData>) {
	const { children, sessionData: providedSessionData } = props;

	const [sessionData, setSessionData] = useState<PrismaJson.ApiSessionData | null>(providedSessionData);

	const hotjarService = useHotjarService();
	const runtimeConfig = useRuntimeConfigProvider<RuntimeConfig>();

	useEffect(() => {
		const fingerprintId = providedSessionData?.fingerprintId;

		if (!fingerprintId) {
			if (runtimeConfig.isLoading || !runtimeConfig.config) {
				return;
			}

			const fpjs = new FingerprintJsProService({
				apiKey: runtimeConfig.config.fpjsPublicKey,
				endpoint: runtimeConfig.config.fpjsEndpoint,
			});

			fpjs.getFingerprintId()
				.then((fingerprintId) => {
					hotjarService.sendEvent('FINGERPRINT_COLLECTED');
					const request = FetchUtils.postJson<SessionData>('/hello', { fingerprintId });
					return request.response;
				})
				.then(setSessionData)
				.catch((err) => {
					console.error(err);
					hotjarService.sendEvent('FINGERPRINT_FAILED');
				});
		} else {
			setSessionData(providedSessionData);
		}
	}, [hotjarService, providedSessionData, runtimeConfig.config, runtimeConfig.isLoading]);

	// Whenever the session data changes, update the Hotjar user data
	useEffect(() => {
		if (sessionData) {
			hotjarService.sendEvent('SESSION_STARTED');

			if (sessionData.user?.id) {
				hotjarService.sendEvent('SESSION_WITH_LOGIN');
			} else {
				hotjarService.sendEvent('SESSION_WITHOUT_LOGIN');
			}

			hotjarService.identify<HotjarUserData>(sessionData.user?.id ?? null, {
				sessionId: sessionData.sessionId,
				fingerprintId: sessionData.fingerprintId,
				email: sessionData.user?.email,
				name: sessionData.user?.fullName,
			});
		} else {
			hotjarService.sendEvent('NO_SESSION');
		}
	}, [hotjarService, sessionData]);

	return <SessionProvider sessionData={sessionData}>{children}</SessionProvider>;
}
