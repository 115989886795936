'use client';
import { useEffect } from 'react';
import { usePathname } from 'next/navigation';
import { useModalProvider } from '@dr-pam/common-components/Components/Modal/ModalProvider';

export default function ModalRouteWatcher() {
	const pathname = usePathname();
	const modalProvider = useModalProvider();

	useEffect(() => {
		modalProvider.closeAllModals();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname]);

	return null;
}
