'use client';
import React, { ReactNode, useState, createContext, useContext, useCallback, Dispatch, SetStateAction } from 'react';
import type {
	CreateSavedForLater,
	SavedForLaterWithRelations,
} from '@dr-pam/common-components/Services/Server/UserService';
import { useUserService } from '@dr-pam/common-components/Services/UserService';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';

export type SavedForLaterContextType = {
	items: SavedForLaterWithRelations[];
	setItems: Dispatch<SetStateAction<SavedForLaterWithRelations[]>>;
	addItem: (saveForLater: CreateSavedForLater) => Promise<void>;
	removeItem: (id: string) => Promise<void>;
	findArticle: (articleId: string) => SavedForLaterWithRelations | null;
	findResource: (resourceId: string) => SavedForLaterWithRelations | null;
	hasLoader: (id: string) => boolean;
	isLoading: boolean;
};

const SavedForLaterContext = createContext<SavedForLaterContextType | null>(null);

export type SavedForLaterProviderProps = {
	initialItems?: SavedForLaterWithRelations[];
	children: ReactNode;
};

export default function SavedForLaterProvider(props: SavedForLaterProviderProps) {
	const { initialItems, children } = props;

	const [items, setItems] = useState<SavedForLaterWithRelations[]>(initialItems ?? []);

	const { addLoader, removeLoader, isLoading, hasLoader } = useLoadTracker();
	const userService = useUserService();

	const addItem = useCallback(
		async (saveForLater: CreateSavedForLater) => {
			const loader = addLoader(saveForLater.articleId ?? saveForLater.resourceId ?? undefined);
			try {
				const request = userService.addSavedForLater(saveForLater);
				const newItem = await request.response;
				setItems((current) => [...current, newItem]);
			} finally {
				removeLoader(loader);
			}
		},
		[addLoader, removeLoader, userService],
	);

	const removeItem = useCallback(
		async (id: string) => {
			const item = items.find((item) => item.id === id);
			const articleId = item?.articleId;
			const resourceId = item?.resourceId;

			const loader = addLoader(articleId ?? resourceId ?? id);
			try {
				const request = userService.removeSavedForLater(id);
				await request.response;
				setItems((current) => current.filter((item) => item.id !== id));
			} finally {
				removeLoader(loader);
			}
		},
		[addLoader, items, removeLoader, userService],
	);

	const findArticle = useCallback(
		(articleId: string) => items.find((item) => item.articleId === articleId) ?? null,
		[items],
	);

	const findResource = useCallback(
		(resourceId: string) => items.find((item) => item.resourceId === resourceId) ?? null,
		[items],
	);

	const value: SavedForLaterContextType = {
		items,
		setItems,
		addItem,
		removeItem,
		findArticle,
		findResource,
		isLoading,
		hasLoader,
	};

	return <SavedForLaterContext.Provider value={value}>{children}</SavedForLaterContext.Provider>;
}

export function useSavedForLater() {
	const context = useContext(SavedForLaterContext);
	if (!context) {
		throw new Error('useSavedForLater must be used within a SavedForLaterProvider');
	}
	return context;
}
